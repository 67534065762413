import React, { useEffect, useState } from 'react'
import Header from '../components/UserHeader'
import {
    Autocomplete,
    Box,
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Modal,
    Pagination,
    Select,
    TextField,
    Checkbox,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { ReportingService } from '../service/ReportingService'
import { useNavigate } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'

const tableInitialValues = {
    rows: [],
    status: {
        loading: false,
        error: false,
        message: '',
    },
    filter: {
        search: '',
        page: 1,
        size: 20,
        total_pages: 1,
        done: false,
    },
}

export const Reporting = () => {
    const navigate = useNavigate()
    const [table, setTable] = useState<any>({
        ...tableInitialValues,
        columns: [
            {
                field: 'index',
                headerName: '№',
                width: 90,
                renderCell: (params: any) =>
                    params.api.getAllRowIds().indexOf(params.id) + 1,
            },
            {
                field: 'client',
                headerName: 'Клиент',
                flex: 1,
                renderCell: (params: any) => params.row.client.full_name,
            },
            {
                field: 'period',
                headerName: 'Отчетный период',
                flex: 1,
                renderCell: (params: any) => params.row.period?.name,
            },
            {
                field: 'reporting_url',
                headerName: 'Ссылка',
                flex: 1,
                renderCell: (params: any) => (
                    <a
                        href={params.value}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                            color: '#1a0dab',
                            textDecoration: 'underline',
                        }}
                    >
                        {params.value}
                    </a>
                ),
            },
            {
                field: 'done',
                headerName: 'Выполнено',
                width: 120,
                renderCell: (params: any) => (
                    <Checkbox
                        checked={params.row.done}
                        onChange={(event) =>
                            handleCheckboxChange(
                                params.row.id,
                                event.target.checked,
                            )
                        }
                    />
                ),
            },
        ],
    })

    const handleCheckboxChange = async (rowId: string, checked: boolean) => {
        try {
            setTable((prevTable: any) => ({
                ...prevTable,
                rows: prevTable.rows.map((row: any) =>
                    row.id === rowId ? { ...row, done: checked } : row,
                ),
            }))

            await ReportingService.UpdateCheckbox({ id: rowId, done: checked })

            setTable((prevTable: any) => ({
                ...prevTable,
                rows: prevTable.rows.filter((row: any) => row.id !== rowId),
            }))
        } catch (error) {
            console.error('Ошибка при обновлении значения done:', error)
        }
    }

    const tableList = ReportingService.GetReportingList(table.filter)

    useEffect(() => {
        if (tableList.loading) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: true,
                },
            }))
        } else if (tableList.error) {
            setTable((prevState: any) => ({
                ...prevState,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: true,
                },
            }))
        } else {
            const data = tableList.result?.data
            setTable((prevState: any) => ({
                ...prevState,
                rows: data.results,
                status: {
                    ...prevState.status,
                    loading: false,
                    error: false,
                },
                filter: {
                    ...prevState.filter,
                    page: data.current_page,
                    total_pages: data.total_pages,
                },
            }))
        }
    }, [tableList.loading, tableList.error, tableList.result?.data])

    return (
        <>
            <Header pageTitle={'Отчетность'} />

            <div className="w-full flex justify-between items-center mt-[103px] mb-[40px]">
                <div className="w-full flex gap-[20px] items-center justify-between">
                    <FormControl className="w-[187px]" variant="standard">
                        <InputLabel>Выполнено</InputLabel>
                        <Select
                            required
                            value={table.filter.done ? 'true' : 'false'}
                            onChange={(event: any) => {
                                setTable({
                                    ...table,
                                    filter: {
                                        ...table.filter,
                                        done:
                                            event.target.value === 'true'
                                                ? true
                                                : false,
                                    },
                                })
                            }}
                        >
                            <MenuItem value="false">Не выполнено</MenuItem>{' '}
                            <MenuItem value="true">Выполнено</MenuItem>{' '}
                        </Select>
                    </FormControl>

                    <TextField
                        label="Поиск"
                        className="w-[269px]"
                        variant="standard"
                        type="text"
                        value={table.filter.search}
                        onChange={(e) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    search: e.target.value,
                                },
                            })
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>

            <Box sx={{ width: '100%', marginBottom: '40px' }}>
                <DataGrid
                    rows={table.rows}
                    columns={table.columns}
                    checkboxSelection={false}
                    keepNonExistentRowsSelected
                    disableColumnFilter
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    loading={table.status.loading}
                    disableRowSelectionOnClick
                    onRowDoubleClick={(params: any) =>
                        navigate(`/clients/${params.row.client.id}`)
                    }
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#979CFF',
                            color: '#FFF',
                        },
                        '.MuiDataGrid-iconButtonContainer': {
                          display: "none"
                        },
                    }}
                />
            </Box>
            <div className="w-full flex gap-[20px] items-center justify-end">
                <div className="flex items-center gap-[10px]">
                    <p>Rows:</p>
                    <TextField
                        label=""
                        variant="outlined"
                        size="small"
                        type="number"
                        sx={{ width: '100px' }}
                        value={table.filter.size}
                        onChange={(event) => {
                            setTable({
                                ...table,
                                filter: {
                                    ...table.filter,
                                    size: event.target.value,
                                },
                            })
                        }}
                    />
                </div>
                <Pagination
                    count={table.filter.total_pages}
                    page={table.filter.page}
                    onChange={(event, value: number) => {
                        setTable({
                            ...table,
                            filter: {
                                ...table.filter,
                                page: value,
                            },
                        })
                    }}
                />
            </div>
        </>
    )
}
