import React, { useEffect, useState } from "react";
import Header from "../components/UserHeader";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { StaffService } from "../service/StaffService";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { checkModalResponse } from "../helpers/helpers";
import KeyOffIcon from "@mui/icons-material/KeyOff";
import KeyIcon from "@mui/icons-material/Key";
import { useSnackbar } from "../helpers/SnackbarProvider";

const tableInitialValues = {
  rows: [],
  status: {
    loading: false,
    error: false,
    message: "",
  },
  filter: {
    search: "",
    start_date: null,
    end_date: null,
    page: 1,
    size: 20,
    total_pages: 1,
  },
};

const modalInitialValues = {
  open: false,
  values: {
    id: "",
    username: "",
    position: "",
    phone: "",
    full_name: "",
    password: "",
    confirm_password: "",
  },
  validation: {
    error: {
      username: false,
      position: false,
      phone: false,
      full_name: false,
      password: false,
    },
    message: {
      username: "",
      position: "",
      phone: "",
      full_name: "",
      password: "",
    },
  },
  requested: false,
  action: "",
  showPassword: false,
};

export const Staffs = () => {
  const { openSnackbar } = useSnackbar();

  const [table, setTable] = useState<any>({
    ...tableInitialValues,
    columns: [
      {
        field: "index",
        headerName: "№",
        width: 120,
        renderCell: (params: any) =>
          params.api.getAllRowIds().indexOf(params.id) + 1,
      },
      {
        field: "date_joined",
        headerName: "Дата",
        flex: 1,
        renderCell: (params: any) =>
          moment(params.row.date_joined).format("DD-MM-YYYY"),
      },
      { field: "full_name", headerName: "Сотрудник", flex: 2 },
      {
        field: "position",
        headerName: "Должность",
        flex: 1,
        renderCell: (params: any) => params.row.position?.name,
      },
      { field: "phone", headerName: "Номер телефона", flex: 1 },
      {
        field: "actions",
        headerName: "Действия",
        width: 110,
        renderCell: (params: any) => (
          <div className="flex justify-start gap-2">
            <IconButton
              onClick={() => {
                setModal({
                  ...modal,
                  open: true,
                  action: "edit",
                  values: {
                    ...params.row,
                    position: params.row.position?.id,
                  },
                });
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setModal({
                  ...modal,
                  open: true,
                  action: "delete",
                  values: {
                    ...params.row,
                    position: params.row.position?.id,
                  },
                });
              }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </div>
        ),
      },
    ],
  });

  const [modal, setModal] = useState(modalInitialValues);

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setModal({
      ...modal,
      requested: true,
    });

    switch (modal.action) {
      case "add":
        StaffService.CreateStaff(modal.values)
          .then(() => {
            setModal(modalInitialValues);
            tableList.execute();
            openSnackbar("Сотрудник успешно добавлен", { variant: "success" });
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setModal, modal);
            openSnackbar("Не удалось добавить сотрудника", {
              variant: "error",
            });
          });
        break;

      case "edit":
        StaffService.UpdateStaff(modal.values)
          .then(() => {
            setModal(modalInitialValues);
            tableList.execute();
            openSnackbar("Сотрудник успешно изменён", { variant: "success" });
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setModal, modal);
            openSnackbar("Не удалось изменить сотрудника", {
              variant: "error",
            });
          });

        break;

      case "delete":
        StaffService.DeleteStaff(modal.values)
          .then(() => {
            setModal(modalInitialValues);
            tableList.execute();
            openSnackbar("Сотрудник успешно удалён", { variant: "success" });
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setModal, modal);
            openSnackbar("Не удалось удалить сотрудника", { variant: "error" });
          });
        break;

      default:
        break;
    }
  };

  const tableList = StaffService.GetStaffList(table.filter);
  const roles = StaffService.GetRoles();

  useEffect(() => {
    if (tableList.loading) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: true,
        },
      }));
    } else if (tableList.error) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: false,
          error: true,
        },
      }));
    } else {
      const data = tableList.result?.data;
      setTable((prevState: any) => ({
        ...prevState,
        rows: data.results,
        status: {
          ...prevState.status,
          loading: false,
          error: false,
        },
        filter: {
          ...prevState.filter,
          page: data.current_page,
          total_pages: data.total_pages,
        },
      }));
    }
  }, [tableList.loading, tableList.error, tableList.result?.data]);

  return (
    <>
      <Header pageTitle={"Сотрудники"} />

      <div className="w-full flex justify-between items-center mt-[103px] mb-[40px]">
        <div></div>
        <div className="">
          <Button
            variant="contained"
            color="black"
            style={{
              borderRadius: "100px",
              padding: "16px 30px",
              lineHeight: "normal",
            }}
            onClick={() => setModal({ ...modal, open: true, action: "add" })}
          >
            Добавить сотрудника
          </Button>
        </div>
      </div>

      <Box sx={{ width: "100%", marginBottom: "40px" }}>
        <DataGrid
          rows={table.rows}
          columns={table.columns}
          checkboxSelection={false}
          keepNonExistentRowsSelected
          disableColumnFilter
          disableColumnMenu
          hideFooter
          autoHeight
          loading={table.status.loading}
          disableRowSelectionOnClick
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#979CFF",
              color: "#FFF",
            },
            '.MuiDataGrid-iconButtonContainer': {
              display: "none"
            },
          }}
        />
      </Box>
      <div className="w-full flex gap-[20px] items-center justify-end">
        <div className="flex items-center gap-[10px]">
          <p>Rows:</p>
          <TextField
            label=""
            variant="outlined"
            size="small"
            type="number"
            sx={{ width: "100px" }}
            value={table.filter.size}
            onChange={(event) => {
              setTable({
                ...table,
                filter: {
                  ...table.filter,
                  size: event.target.value,
                },
              });
            }}
          />
        </div>
        <Pagination
          count={table.filter.total_pages}
          page={table.filter.page}
          onChange={(event, value: number) => {
            setTable({
              ...table,
              filter: {
                ...table.filter,
                page: value,
              },
            });
          }}
        />
      </div>

      <Modal open={modal.open} onClose={() => setModal(modalInitialValues)}>
        <form
          onSubmit={handleFormSubmit}
          className="mainModal relative flex flex-col justify-start items-center"
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
            onClick={() => setModal(modalInitialValues)}
          >
            <CloseIcon />
          </IconButton>

          <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[80px]">
            {modal.action === "add" && "Добавить сотрудника"}
            {modal.action === "edit" && "Редактировать сотрудника"}
            {modal.action === "delete" && "Удалить сотрудника?"}
          </h2>
          {modal.action !== "delete" && (
            <div className="w-full grid grid-cols-2 gap-[30px] mb-[50px]">
              <TextField
                fullWidth
                required
                label="ФИО"
                variant="standard"
                type={"text"}
                value={modal.values.full_name}
                error={modal.validation.error.full_name}
                helperText={modal.validation.message.full_name}
                onChange={(e) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      full_name: e.target.value,
                    },
                  });
                }}
              />
              <TextField
                fullWidth
                required
                label="Номер телефона"
                variant="standard"
                type={"number"}
                value={modal.values.phone}
                error={modal.validation.error.phone}
                helperText={modal.validation.message.phone}
                onChange={(e) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      phone: e.target.value,
                    },
                  });
                }}
              />
              <TextField
                fullWidth
                required
                label="Логин"
                variant="standard"
                type={"text"}
                value={modal.values.username}
                error={modal.validation.error.username}
                helperText={modal.validation.message.username}
                onChange={(e) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      username: e.target.value,
                    },
                  });
                }}
              />
              <FormControl variant="standard">
                <InputLabel>Должность</InputLabel>
                <Select
                  label="Должность"
                  // required
                  value={modal.values.position}
                  error={modal.validation.error.position}
                  onChange={(event: any) => {
                    setModal({
                      ...modal,
                      values: {
                        ...modal.values,
                        position: event.target.value,
                      },
                    });
                  }}
                >
                  {/* <MenuItem value=""><em>None</em></MenuItem> */}
                  {!roles.loading &&
                    !roles.error &&
                    roles.result?.data.map((group: any, index: number) => (
                      <MenuItem key={index} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  {modal.validation.message.position}
                </FormHelperText>
              </FormControl>
              <TextField
                fullWidth
                required={modal.action === "add"}
                label="Пароль"
                variant="standard"
                type={modal.showPassword ? "text" : "password"}
                value={modal.values.password}
                error={modal.validation.error.password}
                helperText={modal.validation.message.password}
                onChange={(e) =>
                  setModal({
                    ...modal,
                    values: { ...modal.values, password: e.target.value },
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setModal({
                            ...modal,
                            showPassword: !modal.showPassword,
                          })
                        }
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {modal.showPassword ? <KeyOffIcon /> : <KeyIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                required={modal.action === "add"}
                label="Подтвердите пароль"
                variant="standard"
                type={modal.showPassword ? "text" : "password"}
                value={modal.values.confirm_password}
                error={modal.values.password !== modal.values.confirm_password}
                helperText={
                  modal.values.password !== modal.values.confirm_password
                    ? "Пароли не совпадают!"
                    : ""
                }
                onChange={(e) =>
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      confirm_password: e.target.value,
                    },
                  })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setModal({
                            ...modal,
                            showPassword: !modal.showPassword,
                          })
                        }
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {modal.showPassword ? <KeyOffIcon /> : <KeyIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          <div className="w-full flex gap-[20px]">
            <LoadingButton
              style={{ borderRadius: "100px" }}
              fullWidth
              variant="outlined"
              color="black"
              onClick={() => setModal(modalInitialValues)}
            >
              Отменить
            </LoadingButton>
            <LoadingButton
              style={{ borderRadius: "100px" }}
              loading={modal.requested}
              fullWidth
              variant="contained"
              color="black"
              type="submit"
            >
              {modal.action === "delete" && "Удалить"}
              {modal.action === "edit" && "Готово"}
              {modal.action === "add" && "Добавить"}
            </LoadingButton>
          </div>
        </form>
      </Modal>
    </>
  );
};
