import { getCookie, setCookie } from "typescript-cookie";
import axios from "axios";
import { logout } from "../store/slices/userSlice";
import { dispatch } from "../index";
import { jwtDecode } from "jwt-decode";
import { removeCookie } from "typescript-cookie";

// export const API_URL = "http://209.97.167.186:8000/api";
export const API_URL = "http://165.232.126.53:8000/api";
export const access_token_name = "agama_access_token";
export const refresh_token_name = "agama_refresh_token";

export const access_token = getCookie(access_token_name);
export const refresh_token = getCookie(refresh_token_name);

export const $axios = axios.create({
  withCredentials: false,
  baseURL: API_URL,
});

// $axios.interceptors.request.use(
//   async (config) => {
//     if (access_token && refresh_token) {
//       config.headers.Authorization = `JWT ${access_token}`;
//       if (
//         config.url?.includes("/clients/create/") ||
//         config.url?.includes("/clients/update/")
//       ) {
//         config.headers["Content-Type"] = "multipart/form-data";
//       } else {
//         config.headers["Content-Type"] = "application/json";
//       }
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

$axios.interceptors.request.use(
  async (config) => {
    if (access_token && refresh_token) {
      config.headers.Authorization = `JWT ${access_token}`;
      if (
        config.url?.includes("/clients/create/") ||
        config.url?.includes("/clients/update/") ||
        config.url?.includes("/file/create/")
      ) {
        config.headers["Content-Type"] = "multipart/form-data";
      } else {
        config.headers["Content-Type"] = "application/json";
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

$axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newAccessToken = await refreshAccessToken();
        if (newAccessToken) {
          originalRequest.headers.Authorization = `JWT ${newAccessToken}`;
          return $axios(originalRequest);
        }
      } catch (refreshError) {
        console.error("Failed to refresh access token:", refreshError);
      }
    }

    return Promise.reject(error);
  }
);

const refreshAccessToken = async () => {
  const refreshToken = getCookie(refresh_token_name);
  if (!refreshToken) {
    removeAuthentication();
    throw new Error("Refresh token not found.");
  }

  try {
    const response = await axios.post(
      `${API_URL}/token/refresh/`,
      { refresh: refreshToken },
      { headers: { "Content-Type": "application/json" } }
    );

    const newAccessToken = response.data.access;
    const accessTokenExpiry: any = jwtDecode(newAccessToken).exp;
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const expiresIn: any = accessTokenExpiry - currentTimeInSeconds;

    setCookie(access_token_name, newAccessToken, { expires: expiresIn });
    return newAccessToken;
  } catch (refreshError) {
    removeAuthentication();
    throw refreshError;
  }
};

export const removeAuthentication = () => {
  removeCookie(access_token_name);
  removeCookie(refresh_token_name);
};

export default $axios;
