import React, { useEffect, useState } from "react";
import Header from "../components/UserHeader";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Pagination,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { ExpenseService } from "../service/ExpenseService";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import { checkModalResponse } from "../helpers/helpers";
import { StaffService } from "../service/StaffService";
import { useSnackbar } from "../helpers/SnackbarProvider";
import { SalaryService } from "../service/SalaryService";
import { useSelector } from "react-redux";
import { ru } from "date-fns/locale/ru";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers";

export const Expenses = () => {
  const user = useSelector(
    (state: any) => state.userData?.user?.position?.name
  );

  const userId = useSelector((state: any) => state.userData?.user?.id);

  const { openSnackbar } = useSnackbar();

  const tableInitialValues = {
    rows: [],
    status: {
      loading: false,
      error: false,
      message: "",
    },
    filter: {
      search: "",
      start_date: null,
      end_date: null,
      page: 1,
      size: 20,
      total_pages: 1,
      type: "",
    },
    columns: [
      {
        field: "index",
        headerName: "№",
        width: 90,
        renderCell: (params: any) =>
          params.api.getAllRowIds().indexOf(params.id) + 1,
      },
      {
        field: "type_of_registration",
        headerName: "Юр. оформление",
        flex: 1,
        renderCell: (params: any) => params.row.type_of_registration?.name,
      },
      { field: "amount", headerName: "Сумма", flex: 1 },
      { field: "amount_deposited", headerName: "Внесенная сумма", flex: 1 },
      {
        field: "remain",
        headerName: "Остаток",
        flex: 1,
        renderCell: (params: any) =>
          params.row.amount - params.row.amount_deposited,
      },
      { field: "note", headerName: "Примечание", flex: 2 },
      {
        field: "date",
        headerName: "Дата",
        width: 140,
        renderCell: (params: any) =>
          moment(params.row.date).format("DD-MM-YYYY HH:mm").toString(),
      },
      {
        field: "actions",
        headerName: "Действия",
        width: 110,
        renderCell: (params: any) => (
          <div className="flex justify-start gap-2">
            <IconButton
              onClick={() => {
                setModal({
                  ...modal,
                  open: true,
                  action: "edit",
                  values: {
                    ...params.row,
                    type: params.row.type.id,
                    user: params.row.user ? params.row.user.id : "",
                    type_of_registration: params.row.type_of_registration?.id,
                  },
                });
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setModal({
                  ...modal,
                  open: true,
                  action: "delete",
                  values: {
                    ...params.row,
                    type: params.row?.type?.id,
                    user: params.row.user ? params.row?.user?.id : "",
                    type_of_registration: params.row?.type_of_registration?.id,
                  },
                });
              }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </div>
        ),
      },
    ],
  };
  const [table, setTable] = useState<any>(tableInitialValues);

  const modalInitialValues = {
    open: false,
    values: {
      id: "",
      title: "",
      amount: "",
      amount_deposited: "",
      note: "",
      type: "",
      user: "",
      type_of_registration: "",
    },
    validation: {
      error: {
        title: false,
        amount: false,
        amount_deposited: false,
        note: false,
        type: false,
        user: false,
        type_of_registration: false,
      },
      message: {
        title: "",
        amount: "",
        amount_deposited: "",
        note: "",
        type: "",
        user: "",
        type_of_registration: "",
      },
    },
    requested: false,
    action: "",
  };
  const [modal, setModal] = useState({
    ...modalInitialValues,
    values: {
      ...modalInitialValues.values,
      type: table.filter.type,
    },
  });

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setModal({
      ...modal,
      requested: true,
    });

    switch (modal.action) {
      case "add":
        ExpenseService.CreateExpense(modal.values)
          .then(() => {
            setModal(modalInitialValues);
            tableList.execute();
            openSnackbar("Расход успешно добавлен", { variant: "success" });
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setModal, modal);
            openSnackbar("Не удалось добавить расход", { variant: "error" });
          });

        break;

      case "edit":
        ExpenseService.UpdateExpense(modal.values)
          .then(() => {
            setModal(modalInitialValues);
            tableList.execute();
            openSnackbar("Расход успешно изменён", { variant: "success" });
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setModal, modal);
            openSnackbar("Не удалось изменить расход", { variant: "error" });
          });

        break;

      case "delete":
        ExpenseService.DeleteExpense(modal.values)
          .then(() => {
            setModal(modalInitialValues);
            tableList.execute();
            openSnackbar("Расход успешно удалён", { variant: "success" });
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setModal, modal);
            openSnackbar("Не удалось удалить расход", { variant: "error" });
          });
        break;

      default:
        break;
    }
  };

  const tableList = ExpenseService.GetExpenseList(table.filter);

  const expenseTypes = ExpenseService.GetExpenseTypes();

  const expenseRegistrationTypes = ExpenseService.GetExpenseRegistrationTypes();

  // const totalAmountCategories = ExpenseService.GetTotalAmountByCategories();

  const staffList = StaffService.GetAllStaffList();

  const salaryStaffData = SalaryService.GetSalaryByUserid(userId);

  const changeTableColumns = (type: any) => {
    const newColumn =
      type.slug === "salary"
        ? {
            field: "user",
            headerName: "Сотрудник",
            flex: 1,
            renderCell: (params: any) => params.row.user?.full_name,
          }
        : {
            field: "title",
            headerName: "Название",
            flex: 1,
          };

    setTable({
      ...table,
      filter: {
        ...table.filter,
        type: type?.id,
      },
      columns: [
        tableInitialValues.columns[0],
        newColumn,
        ...tableInitialValues.columns.slice(1),
      ],
    });
    setModal({
      ...modal,
      values: {
        ...modal.values,
        type: type?.id,
      },
    });
  };

  // useEffect(() => {
  //   // value={modal.values.type === 2 ? modal.values.amount : !salaryStaffData.error && !salaryStaffData.loading && salaryStaffData.result?.data.length > 0 ? salaryStaffData?.result?.data[0].total_salary : 0}
  //   if (salaryStaffData.result && salaryStaffData.result.data.length > 0) {
  //     const staffSalary = salaryStaffData.result.data[0].total_salary;
  //     setModal((prev: any) => ({
  //       ...prev,
  //       values: { ...modal.values, amount: staffSalary },
  //     }));
  //   }
  // }, [
  //   salaryStaffData.loading,
  //   salaryStaffData.error,
  //   salaryStaffData.result?.data,
  // ]);

  useEffect(() => {
    if (tableList.loading) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: true,
        },
      }));
    } else if (tableList.error) {
      setTable((prevState: any) => ({
        ...prevState,
        status: {
          ...prevState.status,
          loading: false,
          error: true,
        },
      }));
    } else {
      const data = tableList.result?.data;
      setTable((prevState: any) => ({
        ...prevState,
        rows: data.results,
        status: {
          ...prevState.status,
          loading: false,
          error: false,
        },
        filter: {
          ...prevState.filter,
          page: data.current_page,
          total_pages: data.total_pages,
        },
      }));
    }
  }, [tableList.loading, tableList.error, tableList.result?.data]);
  useEffect(() => {
    if (
      !expenseTypes.loading &&
      !expenseTypes.error &&
      expenseTypes.result?.data.length > 0
    ) {
      // const newColumn =
      //   expenseTypes.result?.data[expenseTypes.result?.data.length - 1].slug ===
      //   "salary"
      //     ? {
      //         field: "user",
      //         headerName: "Сотрудник",
      //         flex: 1,
      //         renderCell: (params: any) => params.row.user?.full_name,
      //       }
      //     : {
      //         field: "title",
      //         headerName: "Название",
      //         flex: 1,
      //       };

      let newColumn;
      if (user === "Менеджер" || user === "Помощник бухгалтера") {
        newColumn = {
          field: "title",
          headerName: "Название",
          flex: 1,
        };
      } else {
        newColumn =
          expenseTypes.result?.data[expenseTypes.result?.data.length - 1]
            .slug === "salary"
            ? {
                field: "user",
                headerName: "Сотрудник",
                flex: 1,
                renderCell: (params: any) => params.row.user?.full_name,
              }
            : {
                field: "title",
                headerName: "Название",
                flex: 1,
              };
      }

      setTable({
        ...table,
        filter: {
          ...table.filter,
          //   type: expenseTypes.result?.data[0].id,
          type:
            user !== "Менеджер" && user !== "Помощник бухгалтера"
              ? expenseTypes.result?.data[1]?.id
              : expenseTypes.result?.data[0]?.id,
        },
        columns: [
          tableInitialValues.columns[0],
          newColumn,
          ...tableInitialValues.columns.slice(1),
        ],
      });
      setModal({
        ...modal,
        values: {
          ...modal.values,
          type: expenseTypes.result?.data[0].id,
        },
      });
    }
  }, [expenseTypes.loading, expenseTypes.error, expenseTypes.result]);

  return (
    <>
      <Header pageTitle={"Расходы"} />

      <div className="w-full flex justify-between items-center mt-[103px] mb-[40px]">
        <div className="flex items-end gap-[20px]">
          {!expenseTypes.loading &&
            !expenseTypes.error &&
            expenseTypes.result?.data
              .filter((type: any) => {
                if (user === "Менеджер" || user === "Помощник бухгалтера") {
                  return type.name !== "зарплата";
                }
                return true;
              })
              .map((type: any, index: any) => (
                <Button
                  variant={
                    type.id === table.filter.type ? "contained" : "outlined"
                  }
                  color="black"
                  key={index}
                  sx={{ borderRadius: "100px" }}
                  onClick={() => changeTableColumns(type)}
                >
                  {type.name}
                </Button>
              ))}

          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <DatePicker
              label="Месяц"
              className="w-[130px]"
              onChange={(newValue: any) => {
                if (newValue) {
                  const formattedDate = moment(newValue).format("YYYY-MM-DD");
                  setTable({
                    ...table,
                    filter: {
                      ...table.filter,
                      date: formattedDate,
                    },
                  });
                }
              }}
              slotProps={{ textField: { variant: "standard" } }}
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2050-12-31")}
            />
          </LocalizationProvider>
        </div>

        <div className="">
          <Button
            variant="contained"
            color="black"
            style={{
              borderRadius: "100px",
              padding: "16px 30px",
              lineHeight: "normal",
            }}
            onClick={() => setModal({ ...modal, open: true, action: "add" })}
          >
            Добавить расход
          </Button>
        </div>
      </div>
      {/*
      <div className="flex items-center gap-[20px] mb-[40px]">
        <TextField
          label="Поиск расхода"
          variant="standard"
          type="text"
          value={table.filter.search}
          onChange={(e) => {
            setTable({
              ...table,
              filter: {
                ...table.filter,
                search: e.target.value,
              },
            });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div> */}
      <Box sx={{ width: "100%", marginBottom: "40px" }}>
        <DataGrid
          rows={table.rows}
          columns={table.columns}
          checkboxSelection={false}
          keepNonExistentRowsSelected
          disableColumnFilter
          disableColumnMenu
          hideFooter
          autoHeight
          loading={table.status.loading}
          disableRowSelectionOnClick
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#979CFF",
              color: "#FFF",
            },
            '.MuiDataGrid-iconButtonContainer': {
                          display: "none"
            },
          }}
        />
      </Box>
      <div className="w-full flex gap-[20px] items-center justify-end">
        <div className="flex items-center gap-[10px]">
          <p>Rows:</p>
          <TextField
            label=""
            variant="outlined"
            size="small"
            type="number"
            sx={{ width: "100px" }}
            value={table.filter.size}
            onChange={(event) => {
              setTable({
                ...table,
                filter: {
                  ...table.filter,
                  size: event.target.value,
                },
              });
            }}
          />
        </div>
        <Pagination
          count={table.filter.total_pages}
          page={table.filter.page}
          onChange={(event, value: number) => {
            setTable({
              ...table,
              filter: {
                ...table.filter,
                page: value,
              },
            });
          }}
        />
      </div>

      {/* <div className="flex gap-[50px] px-[30px] py-[20px] shadow-base rounded-[10px] border-[#f2f2f2] border-[2px]">
        {!totalAmountCategories.loading &&
          !totalAmountCategories.error &&
          totalAmountCategories?.result?.data.map((item: any) => (
            <div>
              <h4 className="pb-[10px] border-[#111] border-b-[1px] font-bold text-[14px] text-[#282828]">
                {item.name}
              </h4>
              <span className="inline-block mt-[10px] font-medium text-[14px[">
                {item.total_amount.toLocaleString("ru-RU")}
              </span>
            </div>
          ))}
      </div> */}

      <Modal
        open={modal.open}
        onClose={() =>
          setModal({
            ...modalInitialValues,
            values: { ...modalInitialValues.values, type: modal.values.type },
          })
        }
      >
        <form
          onSubmit={handleFormSubmit}
          className="mainModal relative flex flex-col justify-start items-center"
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
            onClick={() => setModal(modalInitialValues)}
          >
            <CloseIcon />
          </IconButton>

          <h2 className="text-[30px] font-[600] text-[#1E1C2A] mb-[80px]">
            {modal.action === "add" && "Добавить расход"}
            {modal.action === "edit" && "Редактировать расход"}
            {modal.action === "delete" && "Удалить расход?"}
          </h2>
          {modal.action !== "delete" && (
            <div className="w-full grid grid-cols-2 gap-[30px] mb-[50px]">
              <FormControl variant="standard">
                <InputLabel>Тип расхода</InputLabel>
                <Select
                  label="Тип расхода"
                  required
                  value={modal.values.type}
                  error={modal.validation.error.type}
                  onChange={(event: any) => {
                    const selectedTypeId = event.target.value;
                    const selectedType = expenseTypes.result?.data.find(
                      (type: any) => type.id === selectedTypeId
                    );
                    setModal({
                      ...modal,
                      values: {
                        ...modal.values,
                        type: selectedTypeId,
                        amount:
                          selectedType && selectedType.name === "основной"
                            ? ""
                            : modal.values.amount,
                      },
                    });
                  }}
                >
                  {!expenseTypes.loading &&
                    !expenseTypes.error &&
                    expenseTypes.result?.data
                      .filter((type: any) => {
                        if (
                          user === "Менеджер" ||
                          user === "Помощник бухгалтера"
                        ) {
                          return type.name !== "зарплата";
                        }
                        return true;
                      })
                      .map((type: any, index: number) => (
                        <MenuItem key={index} value={type.id}>
                          {type.name}
                        </MenuItem>
                      ))}
                </Select>
                <FormHelperText>{modal.validation.message.type}</FormHelperText>
              </FormControl>

              {!expenseTypes.loading &&
                !expenseTypes.error &&
                (() => {
                  const selectedType = expenseTypes.result?.data.find(
                    (type: any) => type.id === modal.values.type
                  );
                  if (selectedType) {
                    if (selectedType.slug === "salary") {
                      return (
                        <FormControl variant="standard">
                          <InputLabel>Сотрудник</InputLabel>
                          <Select
                            label="Сотрудник"
                            required
                            value={modal.values.user}
                            error={modal.validation.error.user}
                            onChange={(event: any) => {
                              const selectedUserId = event.target.value;
                              const selectedUser = staffList.result?.data.find(
                                (staff: any) => staff.id === selectedUserId
                              );

                              if (selectedUser) {
                                setModal({
                                  ...modal,
                                  values: {
                                    ...modal.values,
                                    user: selectedUserId,
                                    amount: selectedUser.balance,
                                  },
                                });
                              } else {
                                setModal({
                                  ...modal,
                                  values: {
                                    ...modal.values,
                                    user: selectedUserId,
                                  },
                                });
                              }
                            }}
                          >
                            {!staffList.loading &&
                              !staffList.error &&
                              staffList.result?.data.map(
                                (staff: any, index: number) => (
                                  <MenuItem key={index} value={staff.id}>
                                    {staff.full_name}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                          <FormHelperText>
                            {modal.validation.message.user}
                          </FormHelperText>
                        </FormControl>
                      );
                    } else {
                      return (
                        <TextField
                          fullWidth
                          required
                          label="Название"
                          variant="standard"
                          type={"text"}
                          value={modal.values.title}
                          error={modal.validation.error.title}
                          helperText={modal.validation.message.title}
                          onChange={(e) => {
                            setModal({
                              ...modal,
                              values: {
                                ...modal.values,
                                title: e.target.value,
                              },
                            });
                          }}
                        />
                      );
                    }
                  }
                })()}

              <FormControl variant="standard">
                <InputLabel>Тип регистрации</InputLabel>
                <Select
                  label="Тип регистрации"
                  required
                  value={modal.values.type_of_registration}
                  error={modal.validation.error.type_of_registration}
                  onChange={(event: any) => {
                    setModal({
                      ...modal,
                      values: {
                        ...modal.values,
                        type_of_registration: event.target.value,
                      },
                    });
                  }}
                >
                  {/* <MenuItem value=""><em>None</em></MenuItem> */}
                  {!expenseRegistrationTypes.loading &&
                    !expenseRegistrationTypes.error &&
                    expenseRegistrationTypes.result?.data.map(
                      (registration: any, index: number) => (
                        <MenuItem key={index} value={registration.id}>
                          {registration.name}
                        </MenuItem>
                      )
                    )}
                </Select>
                <FormHelperText>
                  {modal.validation.message.type_of_registration}
                </FormHelperText>
              </FormControl>

              <TextField
                fullWidth
                required
                label="Сумма"
                variant="standard"
                type={"number"}
                value={modal.values.amount}
                error={modal.validation.error.amount}
                helperText={modal.validation.message.amount}
                InputProps={{ readOnly: modal.values.type.id === 1 }}
                onChange={(e) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      amount: e.target.value,
                    },
                  });
                }}
              />

              <TextField
                fullWidth
                label="Внесенная сумма"
                variant="standard"
                type={"number"}
                value={modal.values.amount_deposited}
                error={modal.validation.error.amount_deposited}
                helperText={modal.validation.message.amount_deposited}
                onChange={(e) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      amount_deposited: e.target.value,
                    },
                  });
                }}
              />

              <TextField
                fullWidth
                label="Примечание"
                variant="standard"
                type={"text"}
                className="col-start-1 col-end-3"
                value={modal.values.note}
                error={modal.validation.error.note}
                helperText={modal.validation.message.note}
                onChange={(e) => {
                  setModal({
                    ...modal,
                    values: {
                      ...modal.values,
                      note: e.target.value,
                    },
                  });
                }}
              />
            </div>
          )}
          <div className="w-full flex gap-[20px]">
            <LoadingButton
              style={{ borderRadius: "100px" }}
              fullWidth
              variant="outlined"
              color="black"
              onClick={() => setModal(modalInitialValues)}
            >
              Отменить
            </LoadingButton>
            <LoadingButton
              style={{ borderRadius: "100px" }}
              loading={modal.requested}
              fullWidth
              variant="contained"
              color="black"
              type="submit"
            >
              {modal.action === "delete" && "Удалить"}
              {modal.action === "edit" && "Готово"}
              {modal.action === "add" && "Добавить"}
            </LoadingButton>
          </div>
        </form>
      </Modal>
    </>
  );
};
