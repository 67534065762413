import React, {createContext, useContext, useState} from "react";
import Slide, {SlideProps} from '@mui/material/Slide';
import {Snackbar} from "@mui/material";
import {Alert} from "@mui/lab";

type SnackbarContextType = {
    openSnackbar: (message: any, config?: SnackbarConfig) => void;
    closeSnackbar: () => void;
}

type SnackbarProviderProps = {
    children: React.ReactNode;
}

type SnackbarConfig = {
    variant?: "success" | "error" | "warning" | "info"
    autoHideDuration?: number
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="down"/>;
}

const initialState = {
    open: false,
    content: "",
    autoHideDuration: 2000

}

const SnackbarProvider = (props: SnackbarProviderProps) => {
    const [state, setState] = React.useState<any>(initialState);

    // const [isOpen, setIsOpen] = useState<boolean>(false)
    // const [snackbarContent, setSnackbarContent] = useState<any>('')
    // const [snackbarTimeout, setSnackbarTimeout] = useState<any>(null)


    const openSnackbar = (message: any, config?: SnackbarConfig) => {
        const customConfig = config ? {...config} : {}
        setState((prev: any) => ({
            ...prev,
            open: true,
            content: message,
            ...customConfig
        }));
    }
    const closeSnackbar = () => {
        setState((prev: any) => ({...prev, open: false}))
    }

    return <SnackbarContext.Provider value={{openSnackbar, closeSnackbar}}>
        <>
            <Snackbar open={state.open} anchorOrigin={{vertical: "top", horizontal: "center"}}
                      TransitionComponent={SlideTransition} autoHideDuration={state.autoHideDuration}
                      onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar}
                       severity={state.variant ? state.variant : "info"}
                       variant="filled"
                       sx={{width: '100%'}}>
                    {state.content}
                </Alert>
            </Snackbar>
            {props.children}
        </>
    </SnackbarContext.Provider>
}

export default SnackbarProvider

export const useSnackbar = () => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error("useSnackbar must be used within a SnackbarProvider");
    }
    return context;
}