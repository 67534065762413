import React, { useEffect, useState } from "react";
import Header from "../components/UserHeader";
import { ClientService } from "../service/ClientService";
import { LoadingButton } from "@mui/lab";
import { checkModalResponse, RemoveEmptyObj } from "../helpers/helpers";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  styled,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { StaffService } from "../service/StaffService";
import { DatePicker } from "@mui/x-date-pickers";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { convertImageUrlToFile } from "../helpers/helpers";
import dayjs from "dayjs";
import { ReportingService } from "../service/ReportingService";
import { useSnackbar } from "../helpers/SnackbarProvider";
import { useSelector } from "react-redux";
import { ru } from "date-fns/locale/ru";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

const formInitialValues = {
  values: {
    id: "",
    reviews: [],
    files: [],
    full_name: "",
    contacts: "",
    address: "",
    type_of_registration: "",
    niche: "",
    expected_amount: "",
    date_of_payment: null,
    date_of_birth: null,
    domain_expiration_date: null,
    site: "",
    account_number: "",
    card_number: "",
    analytics: "",
    metric: "",
    goals: "",
    tm: "",
    itn: "",
    // reporting: "",
    requisites: "",
    payment_invoice_number: "",
    case: "",
    comment: "",
    manager: "",
    status: "",
    reports: {
      reporting_url: "",
      period: "",
    },
  },
  validation: {
    error: {
      full_name: false,
      contacts: false,
      address: false,
      date_of_birth: false,
      type_of_registration: false,
      niche: false,
      expected_amount: false,
      date_of_payment: false,
      site: false,
      domain_expiration_date: false,
      account_number: false,
      card_number: false,
      analytics: false,
      metric: false,
      goals: false,
      tm: false,
      itn: false,
      reporting: false,
      requisites: false,
      payment_invoice_number: false,
      case: false,
      comment: false,
      manager: false,
      status: false,
      reports: {
        reporting_url: false,
        period: false,
      },
    },
    message: {
      full_name: "",
      contacts: "",
      address: "",
      date_of_birth: "",
      type_of_registration: "",
      niche: "",
      expected_amount: "",
      date_of_payment: "",
      site: "",
      domain_expiration_date: "",
      account_number: "",
      card_number: "",
      analytics: "",
      metric: "",
      goals: "",
      tm: "",
      itn: "",
      reporting: "",
      requisites: "",
      payment_invoice_number: "",
      case: "",
      comment: "",
      manager: "",
      status: "",
      reports: {
        reporting_url: "",
        period: "",
      },
    },
  },
  requested: false,
  action: "",
  showPassword: false,
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const ClientsDetails = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const action = params.get("action");
  const client_id = params.get("client_id");
  const [form, setForm] = useState<any>({
    ...formInitialValues,
    action: action,
  });

  const user = useSelector((state: any) => state.userData.user.position.name);
  const userId = useSelector((state: any) => state.userData.user.id);

  const managers = StaffService.GetManagers();
  const registrationTypes = ClientService.GetRegistrationTypes();
  const statuses = ClientService.GetStatuses();
  const periods = ReportingService.GetPeriodsList();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    let newData = form.values;

    if (form.action !== 'edit') {
      newData = RemoveEmptyObj(form.values);
    }

    let form_data = new FormData();
    for (let key in newData) {
      if (Array.isArray(newData[key])) {
        for (let i = 0; i < newData[key].length; i++) {
          form_data.append(`${key}[${i}]file`, newData[key][i]);
        }
      } else if (typeof newData[key] === "object" && key === "reports") {
        if (newData[key]) {
          for (let keyIn in newData[key]) {
            form_data.append(`${key}.${keyIn}`, newData[key][keyIn]);
          }
        }
      } else {
        if (
          key === "date_of_birth" ||
          key === "date_of_payment" ||
          key === "domain_expiration_date"
        ) {
          let parsedDate =  moment(newData[key]).format("DD-MM-YYYY").toString()

          if (form.action === "edit") {
            form_data.append(
              key,
              parsedDate && parsedDate !== "Invalid date" ? parsedDate : ""
            );
          } else {
              if (parsedDate) {
                form_data.append(
                  key,
                  parsedDate
                );
              }
          }
        } else {
          console.log(newData[key])
          form_data.append(key, newData[key] && newData[key] !== "null" ? newData[key] : "");
        }
      }

    }
    setForm({
      ...form,
      requested: true,
    });

    switch (form.action) {
      case "edit":
        ClientService.UpdateClient(form_data, form.values.id)
          .then((res) => {
            navigate({
              pathname: "/clients",
            });
            openSnackbar("Клиент успешно изменен", { variant: "success" });
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setForm, form);
            openSnackbar("Не удалось изменить клиента", { variant: "error" });
          });
        break;

      case "add":
        ClientService.CreateClient(form_data)
          .then((res) => {
            setForm({
              ...form,
              requested: false,
            });
            navigate({
              pathname: "/clients/",
              search: createSearchParams({
                action: "edit",
                client_id: res.data.id,
              }).toString(),
            });
            openSnackbar("Клиент успешно добавлен", { variant: "success" });
            // window.location.reload();
          })
          .catch((err) => {
            checkModalResponse(err.response.data, setForm, form);
            openSnackbar("Не удалось добавить клиента", { variant: "error" });
          });
        break;
    }
  };

  useEffect(() => {
    if (user === "Менеджер") {
      setForm((prevForm: any) => ({
        ...prevForm,
        values: {
          ...prevForm.values,
          manager: userId,
        },
      }));
    }
  }, [user, userId]);

  useEffect(() => {
    if (client_id !== null && action === "edit") {
      ClientService.GetClient(client_id).then(async (res: any) => {
        const fileConversionPromises = res.data.files?.map((file: any) => {
          return convertImageUrlToFile(file.file, file.name).then(
            (res: any) => res
          );
        });

        const convertedFiles = await Promise.all(fileConversionPromises);

        const reviewsConversionPromises = res.data.reviews?.map(
          (review: any) => {
            return convertImageUrlToFile(review.file, review.name).then(
              (res: any) => res
            );
          }
        );
        const convertedReviews = await Promise.all(reviewsConversionPromises);

        // const convertedCase = await Promise.all([
        //   convertImageUrlToFile(
        //     res.data.case,
        //     res.data.case?.split("/").pop()
        //   ).then((res: any) => res),
        // ]);

        const reportsData = res.data.reports
          ? {
              period: res.data.reports.period?.id || {},
              reporting_url: res.data.reports.reporting_url || "",
            }
          : {
              period: {},
              reporting_url: "",
            };

        setForm({
          ...form,
          values: {
            ...res.data,
            type_of_registration: res.data.type_of_registration?.id,
            status: res.data?.status?.id,
            manager: res.data.manager?.id,
            date_of_payment: moment(
              res.data.date_of_payment,
              "YYYY-MM-DD"
            ).format("YYYY-MM-DD"),
            date_of_birth: moment(res.data.date_of_birth, "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            ),
            domain_expiration_date: moment(
              res.data.domain_expiration_date,
              "YYYY-MM-DD"
            ).format("YYYY-MM-DD"),
            files: convertedFiles,
            reviews: convertedReviews,
            // case: convertedCase[0] || "",
            // case: res.data.case
            // service: res.data.service?.id,
            // reports: {
            //   period: "",
            //   reporting_url: "",
            // },
            requisites: res.data?.requisites,
            reports: reportsData,
          },
          action: "edit",
        });
      });
    }
  }, []);

  return (
    <>
      <Header
        pageTitle={
          action === "edit" ? "Редактирование клиента" : "Добавление клиента"
        }
      />

      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col justify-start items-center mt-[58px]"
      >
        <div className="w-full flex mb-[60px]">
          <Button
            variant="text"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/clients")}
          >
            Назад
          </Button>
        </div>

        <div className="w-full grid grid-cols-3 gap-[40px] mb-[70px]">
          <TextField
            fullWidth
            required
            label="ФИО"
            variant="standard"
            type={"text"}
            value={form.values.full_name}
            error={form.validation.error.full_name}
            helperText={form.validation.message.full_name}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  full_name: e.target.value,
                },
              });
            }}
          />

          {user !== "Менеджер" && (
            <FormControl variant="standard">
              <InputLabel>Менеджер</InputLabel>
              <Select
                label="Менеджер"
                required
                value={form.values.manager}
                error={form.validation.error.manager}
                onChange={(event: any) => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      manager: event.target.value,
                    },
                  });
                }}
              >
                {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}
                {!managers.loading &&
                  !managers.error &&
                  managers.result?.data.map((manager: any, index: number) => (
                    <MenuItem key={index} value={manager.id}>
                      {manager.full_name}
                    </MenuItem>
                  ))}
              </Select>
              <FormHelperText>{form.validation.message.manager}</FormHelperText>
            </FormControl>
          )}

          <TextField
            fullWidth
            label="Контакты"
            variant="standard"
            type={"text"}
            value={form.values.contacts}
            error={form.validation.error.contacts}
            helperText={form.validation.message.contacts}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  contacts: e.target.value,
                },
              });
            }}
          />
          <TextField
            fullWidth
            label="Адрес"
            variant="standard"
            type={"text"}
            value={form.values.address}
            error={form.validation.error.address}
            helperText={form.validation.message.address}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  address: e.target.value,
                },
              });
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <DatePicker
              label="Дата рождения"
              value={form.values.date_of_birth}
              onChange={(newValue) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    date_of_birth: newValue,
                  },
                });
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: false,
                  error: form.validation.error.date_of_birth,
                  helperText: form.validation.message.date_of_birth,
                },
              }}
            />
          </LocalizationProvider>
          <FormControl variant="standard">
            <InputLabel>Вид регистрации</InputLabel>
            <Select
              label="Вид регистрации"
              required
              value={form.values.type_of_registration}
              error={form.validation.error.type_of_registration}
              onChange={(event: any) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    type_of_registration: event.target.value,
                  },
                });
              }}
            >
              {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}
              {!registrationTypes.loading &&
                !registrationTypes.error &&
                registrationTypes.result?.data.map(
                  (registrationType: any, index: number) => (
                    <MenuItem key={index} value={registrationType.id}>
                      {registrationType.name}
                    </MenuItem>
                  )
                )}
            </Select>
            <FormHelperText>
              {form.validation.message.type_of_registration}
            </FormHelperText>
          </FormControl>

          {/* <TextField
                        fullWidth
                        label="Услуга"
                        variant="standard"
                        type={"text"}
                        value={form.values.service}
                        error={form.validation.error.service}
                        helperText={form.validation.message.service}
                        onChange={(e) => {
                            setForm({
                                ...form,
                                values: {
                                    ...form.values,
                                    service: e.target.value,
                                },
                            });
                        }}
                    /> */}

          <TextField
            fullWidth
            label="Ниша"
            variant="standard"
            type={"text"}
            value={form.values.niche}
            error={form.validation.error.niche}
            helperText={form.validation.message.niche}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  niche: e.target.value,
                },
              });
            }}
          />

          <TextField
            fullWidth
            label="Ожидаемая сумма"
            variant="standard"
            type={"number"}
            value={form.values.expected_amount}
            error={form.validation.error.expected_amount}
            helperText={form.validation.message.expected_amount}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  expected_amount: e.target.value,
                },
              });
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <DatePicker
              label="Дата оплаты"
              value={form.values.date_of_payment}
              // onChange={(newValue) => {
              //   setForm({
              //     ...form,
              //     values: {
              //       ...form.values,
              //       date_of_payment: moment(newValue?.$d, "DD-MM-YYYY").format(
              //         "DD-MM-YYYY"
              //       ),
              //     },
              //   });
              // }}
              onChange={(newValue) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    date_of_payment: newValue,
                  },
                });
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: true,
                  error: form.validation.error.date_of_payment,
                  helperText: form.validation.message.date_of_payment,
                },
              }}
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2050-12-31")}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            label="Сайт"
            variant="standard"
            type={"text"}
            value={form.values.site}
            error={form.validation.error.site}
            helperText={form.validation.message.site}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  site: e.target.value,
                },
              });
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
            <DatePicker
              label="Домен до"
              value={form.values.domain_expiration_date}
              // onChange={(newValue) => {
              //   setForm({
              //     ...form,
              //     values: {
              //       ...form.values,
              //       domain_expiration_date: moment(
              //         newValue?.$d,
              //         "DD-MM-YYYY"
              //       ).format("DD-MM-YYYY"),
              //     },
              //   });
              // }}
              onChange={(newValue) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    domain_expiration_date: newValue,
                  },
                });
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  required: false,
                  error: form.validation.error.domain_expiration_date,
                  helperText: form.validation.message.domain_expiration_date,
                },
              }}
              minDate={new Date("2000-01-01")}
              maxDate={new Date("2050-12-31")}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            label="Номер аккаунта"
            variant="standard"
            type={"text"}
            value={form.values.account_number}
            error={form.validation.error.account_number}
            helperText={form.validation.message.account_number}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  account_number: e.target.value,
                },
              });
            }}
          />

          <TextField
            fullWidth
            label="Номер карты"
            variant="standard"
            type="number"
            value={form.values.card_number}
            error={form.validation.error.card_number}
            helperText={form.validation.message.card_number}
            onChange={(event) => {
              const newValue = event.target.value.slice(0, 4);
              setForm({
                ...form,
                values: {
                  ...form.values,
                  card_number: newValue,
                },
              });
            }}
          />

          <TextField
            fullWidth
            label="Аналитика"
            variant="standard"
            type={"text"}
            value={form.values.analytics}
            error={form.validation.error.analytics}
            helperText={form.validation.message.analytics}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  analytics: e.target.value,
                },
              });
            }}
          />
          <TextField
            fullWidth
            label="Метрика"
            variant="standard"
            type={"text"}
            value={form.values.metric}
            error={form.validation.error.metric}
            helperText={form.validation.message.metric}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  metric: e.target.value,
                },
              });
            }}
          />
          <TextField
            fullWidth
            label="Цели"
            variant="standard"
            type={"text"}
            value={form.values.goals}
            error={form.validation.error.goals}
            helperText={form.validation.message.goals}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  goals: e.target.value,
                },
              });
            }}
          />

          <TextField
            fullWidth
            label="ТМ"
            variant="standard"
            type={"text"}
            value={form.values.tm}
            error={form.validation.error.tm}
            helperText={form.validation.message.tm}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  tm: e.target.value,
                },
              });
            }}
          />
          {/* <TextField
            fullWidth
            label="Отчетность"
            variant="standard"
            type={"text"}
            value={form.values.reporting}
            error={form.validation.error.reporting}
            helperText={form.validation.message.reporting}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  reporting: e.target.value,
                },
              });
            }}
          /> */}

          <TextField
            fullWidth
            label="Реквизиты"
            variant="standard"
            type={"text"}
            value={form.values.requisites}
            error={form.validation.error.requisites}
            helperText={form.validation.message.requisites}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  requisites: e.target.value,
                },
              });
            }}
          />
          <TextField
            fullWidth
            label="ИНН"
            variant="standard"
            type={"text"}
            value={form.values.itn}
            error={form.validation.error.itn}
            helperText={form.validation.message.itn}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  itn: e.target.value,
                },
              });
            }}
          />
          <TextField
            fullWidth
            label="Счет на оплату"
            variant="standard"
            type={"text"}
            value={form.values.payment_invoice_number}
            error={form.validation.error.payment_invoice_number}
            helperText={form.validation.message.payment_invoice_number}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  payment_invoice_number: e.target.value,
                },
              });
            }}
          />

          <TextField
            fullWidth
            label="Кейс"
            variant="standard"
            type={"text"}
            value={form.values.case}
            error={form.validation.error.case}
            helperText={form.validation.message.case}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  case: e.target.value,
                },
              });
            }}
          />

          <FormControl variant="standard">
            <InputLabel>Статус</InputLabel>
            <Select
              label="Статус"
              required
              value={form.values.status}
              error={form.validation.error.status}
              onChange={(event: any) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    status: event.target.value,
                  },
                });
              }}
            >
              {!statuses.loading &&
                !statuses.error &&
                statuses.result?.data.map((status: any, index: number) => (
                  <MenuItem key={index} value={status.id}>
                    {status.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>{form.validation.message.status}</FormHelperText>
          </FormControl>

          {/* <TextField
            fullWidth
            label="Кейс"
            variant="standard"
            type={"text"}
            focused={typeof form.values.case !== "string"}
            value={
              typeof form.values.case !== "string" ? form.values.case.name : ""
            }
            error={form.validation.error?.case}
            helperText={form.validation.message.case}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {typeof form.values.case === "string" ? (
                    <IconButton component="label">
                      <AddIcon />
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(e) => {
                          if (e.target.files) {
                            setForm({
                              ...form,
                              values: {
                                ...form.values,
                                case: e.target.files[0],
                              },
                            });
                          }
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setForm({
                          ...form,
                          values: {
                            ...form.values,
                            case: "",
                          },
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          /> */}

          <FormControl variant="standard">
            <InputLabel>Отчетный период</InputLabel>
            <Select
              label="Отчетный период"
              required
              value={form.values.reports.period}
              error={form.validation.error.reports.period}
              onChange={(e) => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    reports: {
                      ...form.values.reports,
                      period: e.target.value,
                    },
                  },
                });
              }}
            >
              {!periods.loading &&
                !periods.error &&
                periods.result?.data.map((period: any, index: number) => (
                  <MenuItem key={index} value={period.id}>
                    {period.name}
                  </MenuItem>
                ))}
            </Select>
            <FormHelperText>
              {form.validation.message.reports.period}
            </FormHelperText>
          </FormControl>

          <TextField
            fullWidth
            label="Ссылка отчета"
            variant="standard"
            type="text"
            value={form.values.reports.reporting_url}
            error={form.validation.error.reports.reporting_url}
            helperText={form.validation.message.reports.reporting_url}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  reports: {
                    ...form.values.reports,
                    reporting_url: e.target.value,
                  },
                },
              });
            }}
          />

          <TextField
            fullWidth
            label="Комментарий..."
            variant="outlined"
            type={"text"}
            className="col-start-1 col-end-4"
            multiline
            rows={6}
            value={form.values.comment}
            error={form.validation.error.comment}
            helperText={form.validation.message.comment}
            onChange={(e) => {
              setForm({
                ...form,
                values: {
                  ...form.values,
                  comment: e.target.value,
                },
              });
            }}
            sx={{
              marginTop: "37px",
              marginBottom: "17px",
            }}
          />

          <div className="w-full flex col-start-1 col-end-4 justify-between items-start">
            <div className={"w-[346px] flex flex-col items-start gap-[30px]"}>
              <h5 className="text-[20px] font-[600] text-[#282828]">Отзывы</h5>
              {form.values.reviews.length > 0 && (
                <div className="w-full flex flex-col items-start gap-[20px]">
                  {form.values.reviews.map((review: any, index: number) => (
                    <div
                      key={index}
                      className="w-full flex justify-between items-center"
                    >
                      <Link
                        component="button"
                        variant="body2"
                        key={index}
                        textAlign="start"
                        onClick={(e) => e.preventDefault()}
                      >
                        {review.name}
                      </Link>
                      <IconButton
                        onClick={() => {
                          const updatedFiles = [
                            ...form.values.reviews.slice(0, index),
                            ...form.values.reviews.slice(index + 1),
                          ];
                          setForm({
                            ...form,
                            values: {
                              ...form.values,
                              reviews: updatedFiles,
                            },
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              )}

              <div className="w-full flex justify-between items-center">
                <h3 className="text-[14px] font-[600] text-[#282828]">
                  Добавить отзыв
                </h3>
                <IconButton component="label">
                  <AddIcon />
                  <VisuallyHiddenInput
                    multiple
                    type="file"
                    onChange={(e) => {
                      const newFiles = e.target.files;

                      if (newFiles) {
                        const reviewsArray = Array.from(newFiles);
                        setForm({
                          ...form,
                          values: {
                            ...form.values,
                            reviews: [...form.values.reviews, ...reviewsArray],
                          },
                        });
                      }
                    }}
                  />
                </IconButton>
              </div>
            </div>
            <div className={"w-[346px] flex flex-col items-start gap-[30px]"}>
              <h5 className="text-[20px] font-[600] text-[#282828]">
                Список файлов
              </h5>

              {form.values.files.length > 0 && (
                <div className="w-full flex flex-col items-start gap-[20px]">
                  {form.values.files.map((file: any, index: number) => (
                    <div
                      key={index}
                      className="w-full flex justify-between items-center"
                    >
                      <Link
                        component="button"
                        variant="body2"
                        key={index}
                        textAlign="start"
                        onClick={(e) => e.preventDefault()}
                      >
                        {file?.name}
                      </Link>
                      <IconButton
                        onClick={() => {
                          const updatedFiles = [
                            ...form.values.files.slice(0, index),
                            ...form.values.files.slice(index + 1),
                          ];
                          setForm({
                            ...form,
                            values: {
                              ...form.values,
                              files: updatedFiles,
                            },
                          });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  ))}
                </div>
              )}
              <div className="w-full flex justify-between items-center">
                <h3 className="text-[14px] font-[600] text-[#282828]">
                  Добавить файл
                </h3>
                <IconButton component="label">
                  <AddIcon />
                  <VisuallyHiddenInput
                    multiple
                    type="file"
                    onChange={(e) => {
                      const newFiles = e.target.files;

                      if (newFiles) {
                        const filesArray = Array.from(newFiles);
                        setForm({
                          ...form,
                          values: {
                            ...form.values,
                            files: [...form.values.files, ...filesArray],
                          },
                        });
                      }
                    }}
                  />
                </IconButton>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full max-w-[300px] flex gap-[20px]">
          <LoadingButton
            style={{ borderRadius: "100px" }}
            fullWidth
            variant="outlined"
            color="black"
            onClick={() => navigate("/clients")}
          >
            Назад
          </LoadingButton>
          <LoadingButton
            style={{ borderRadius: "100px" }}
            loading={form.requested}
            fullWidth
            variant="contained"
            color="black"
            type="submit"
          >
            {form.action === "edit" && "Редактировать"}
            {form.action === "add" && "Добавить"}
          </LoadingButton>
        </div>
      </form>
    </>
  );
};
