import { $axios } from "../https/axiosInstance";
import { CreateCleanSearchParams } from "../helpers/helpers";
import { useAsync } from "react-async-hook";

export const ClientService = {
  GetClientList(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/clients/list/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },

  GetAllClientList() {
    return useAsync(async () => {
      return await $axios.get("/clients/without-pagination/list/");
    }, []);
  },
  async GetClient(client_id: any) {
    return await $axios.get(`/clients/${client_id}/`);
  },
  GetStatuses() {
    return useAsync(async () => {
      return await $axios.get("/clients/statuses/");
    }, []);
  },
  GetRegistrationTypes() {
    return useAsync(async () => {
      return await $axios.get("/clients/type-of-registrations/");
    }, []);
  },
  GetServices() {
    return useAsync(async () => {
      return await $axios.get("/payment-history/service/list/");
    }, []);
  },
  async UpdateClient(client: any, client_id: string) {
    return await $axios.put(`/clients/update/${client_id}/`, client);
  },
  async CreateClient(client: any) {
    return await $axios.post("/clients/create/", client);
  },
  async DeleteClient(client: any) {
    return await $axios.delete(`/clients/${client.id}/`);
  },
};
