import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from '../components/UserHeader'
import { TextField, Button, IconButton } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { Line } from 'react-chartjs-2'
import { SalaryService } from '../service/SalaryService'
import { useSnackbar } from '../helpers/SnackbarProvider'
import { checkModalResponse } from '../helpers/helpers'
import { useSelector } from 'react-redux'
import EditOutlined from '@mui/icons-material/EditOutlined'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import moment from 'moment'
import { AnalyticsService } from '../service/AnalyticsService'
import dayjs from 'dayjs'
import { ru } from 'date-fns/locale/ru'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'

const Analytics = () => {
    const { openSnackbar } = useSnackbar()

    const userId = useSelector((state: any) => state.userData.user.id)

    const user = useSelector(
        (state: any) => state.userData?.user?.position?.name,
    )

    const userDataList = SalaryService.GetSalaryList('')

    const userData = userDataList?.result?.data?.results?.find(
        (item: any) => item.user.id === userId,
    )

    const salaryId = userData?.id

    const ManagerPercentageSum =
        AnalyticsService.GetManagerPercentageSum(userId)
    console.log('ManagerPercentageSum', ManagerPercentageSum)

    const filtersInitialValues = {
        date: {
            date_from: dayjs().subtract(2, 'month').format('YYYY-MM'),
            date_to: dayjs().format('YYYY-MM'),
        },
    }

    const formInitialValues = {
        values: {
            id: '',
            salary: '',
            accountant_percentage: '',
            tax: '',
        },
        validation: {
            error: {
                salary: false,
                accountant_percentage: false,
                tax: false,
            },
            message: {
                salary: '',
                accountant_percentage: '',
                tax: '',
            },
        },
        requested: false,
        action: '',
    }

    const [filter, setFilter] = useState<any>(filtersInitialValues)

    const [form, setForm] = useState<any>({
        ...formInitialValues,
        requested: true,
    })

    const allFieldsFilled =
        userData?.salary !== undefined &&
        userData?.accountant_percentage !== undefined &&
        userData?.tax !== undefined

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault()
        const values = {
            ...form.values,
            user: userId,
            created_at: dayjs().format('YYYY-MM'),
        }

        switch (form.action) {
            case 'add':
                if (userData?.confirm === 'confirmed') {
                    return
                }
                SalaryService.CreateSalary(values)
                    .then((response) => {
                        setForm(formInitialValues)
                        userDataList.execute()
                        openSnackbar('Оклад успешно добавлен', {
                            variant: 'success',
                        })
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setForm, form)
                        openSnackbar('Не удалось добавить оклад', {
                            variant: 'error',
                        })
                    })

                break

            case 'edit':
                SalaryService.UpdateSalary({ ...values, id: salaryId })
                    .then(() => {
                        setForm(formInitialValues)
                        openSnackbar('Оклад успешно изменён', {
                            variant: 'success',
                        })
                        userDataList.execute()
                    })
                    .catch((err) => {
                        checkModalResponse(err.response.data, setForm, form)
                        openSnackbar('Не удалось изменить оклад', {
                            variant: 'error',
                        })
                    })

                break

            default:
                break
        }
    }

    // const analyticsCLients = AnalyticsService.GetAnalyticsClients({
    //   ...filter.date,
    //   ...(filter.date.date_from === null
    //     ? {}
    //     : {
    //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //       }),
    //   ...(filter.date.date_to === null
    //     ? {}
    //     : {
    //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //       }),
    //   user_id: userId,
    // });

    const analyticsCLients = AnalyticsService.GetAnalyticsClients({
        ...filter.date,
        date_from: filter.date.date_from
            ? moment(filter.date.date_from).format('YYYY-MM')
            : null,
        date_to: filter.date.date_to
            ? moment(filter.date.date_to).format('YYYY-MM')
            : null,
        user_id: userId,
    })

    // const analyticsManagerPrecentageSum =
    //   AnalyticsService.GetAnalyticsManagerPrecentageSum({
    //     ...filter.date,
    //     ...(filter.date.date_from === null
    //       ? {}
    //       : {
    //           date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //         }),
    //     ...(filter.date.date_to === null
    //       ? {}
    //       : {
    //           date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //         }),
    //     user_id: userId,
    //   });

    const analyticsManagerPrecentageSum =
        AnalyticsService.GetAnalyticsManagerPrecentageSum({
            ...filter.date,
            date_from: filter.date.date_from
                ? moment(filter.date.date_from).format('YYYY-MM')
                : null,
            date_to: filter.date.date_to
                ? moment(filter.date.date_to).format('YYYY-MM')
                : null,
            user_id: userId,
        })

    // const analyticsManagerSalary = AnalyticsService.GetAnalyticsManagerSalary({
    //   ...filter.date,
    //   ...(filter.date.date_from === null
    //     ? {}
    //     : {
    //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //       }),
    //   ...(filter.date.date_to === null
    //     ? {}
    //     : {
    //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //       }),
    //   user_id: userId,
    // });

    const analyticsManagerSalary = AnalyticsService.GetAnalyticsManagerSalary({
        ...filter.date,
        date_from: filter.date.date_from
            ? moment(filter.date.date_from).format('YYYY-MM')
            : null,
        date_to: filter.date.date_to
            ? moment(filter.date.date_to).format('YYYY-MM')
            : null,
        user_id: userId,
    })

    // const analyticsClientsIncome = AnalyticsService.GetClientIncome({
    //   ...filter.date,
    //   ...(filter.date.date_from === null
    //     ? {}
    //     : {
    //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //       }),
    //   ...(filter.date.date_to === null
    //     ? {}
    //     : {
    //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //       }),
    // });

    const analyticsClientsIncome = AnalyticsService.GetClientIncome({
        ...filter.date,
        date_from: filter.date.date_from
            ? moment(filter.date.date_from).format('YYYY-MM')
            : null,
        date_to: filter.date.date_to
            ? moment(filter.date.date_to).format('YYYY-MM')
            : null,
        user_id: userId,
    })

    // const analyticsClientsExpense = AnalyticsService.GetClientExpense({
    //   ...filter.date,
    //   ...(filter.date.date_from === null
    //     ? {}
    //     : {
    //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //       }),
    //   ...(filter.date.date_to === null
    //     ? {}
    //     : {
    //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //       }),
    // });

    const analyticsClientsExpense = AnalyticsService.GetClientExpense({
        ...filter.date,
        date_from: filter.date.date_from
            ? moment(filter.date.date_from).format('YYYY-MM')
            : null,
        date_to: filter.date.date_to
            ? moment(filter.date.date_to).format('YYYY-MM')
            : null,
        user_id: userId,
    })

    // const analyticsClientsNetprofit = AnalyticsService.GetClientNetprofit({
    //   ...filter.date,
    //   ...(filter.date.date_from === null
    //     ? {}
    //     : {
    //         date_from: moment(filter.date.date_from?.$d).format("YYYY-MM"),
    //       }),
    //   ...(filter.date.date_to === null
    //     ? {}
    //     : {
    //         date_to: moment(filter.date.date_to?.$d).format("YYYY-MM"),
    //       }),
    // });

    const analyticsClientsNetprofit = AnalyticsService.GetClientNetprofit({
        ...filter.date,
        date_from: filter.date.date_from
            ? moment(filter.date.date_from).format('YYYY-MM')
            : null,
        date_to: filter.date.date_to
            ? moment(filter.date.date_to).format('YYYY-MM')
            : null,
        user_id: userId,
    })

    useEffect(() => {
        if (userData) {
            setForm((prevForm: any) => ({
                ...prevForm,
                values: {
                    ...prevForm.values,
                    salary: userData.salary || 0,
                    accountant_percentage: userData.accountant_percentage || 0,
                    tax: userData.tax || 0,
                },
            }))
        }
    }, [userData])

    const [sumManagerPercentageSum, setSumManagerPercentageSum] = useState<
        number | null
    >(null)

    useEffect(() => {
        ManagerPercentageSum.then((result) => {
            const sum = result?.data?.sum || 0
            setSumManagerPercentageSum(sum)
        }).catch((error) => {
            console.error('Error fetching sum:', error)
        })
    }, [])

    const totalSalaryWithPercentageSum = userData?.total_salary || 0

    return (
        <div className="w-full">
            <div className="w-full flex justify-between items-end mb-[70px]">
                <div className="w-full flex flex-col gap-[24px] text-[white]">
                    <Header pageTitle={'Главная'} />
                </div>
            </div>

            {user !== 'Администратор' && (
                <div className="py-[40px] px-[30px] border-[1px] border-[#D4D4D4] rounded-[10px]">
                    <div className="flex gap-2 text-[#4768DF]">
                        <span className="font-semibold">Общий баланс:</span>
                        <span>{userData?.user?.balance}</span>
                    </div>

                    <h4 className="pb-[30px] mt-[25px] font-bold text-[26px] text-[#282828]">
                        Зарплата
                    </h4>

                    <form
                        onSubmit={handleFormSubmit}
                        className="w-full flex gap-[40px]"
                    >
                        <TextField
                            fullWidth
                            // required
                            label="Оклад"
                            variant="standard"
                            type="text"
                            disabled={allFieldsFilled && form.action !== 'edit'}
                            value={form.values.salary}
                            error={form.validation.error.salary}
                            helperText={form.validation.message.salary}
                            onChange={(e) => {
                                const value = e.target.value
                                setForm((prevForm: any) => ({
                                    ...prevForm,
                                    values: {
                                        ...prevForm.values,
                                        salary: value,
                                    },
                                }))
                            }}
                        />

                        <TextField
                            fullWidth
                            // required
                            label="Сумма бухгалтера"
                            variant="standard"
                            disabled={allFieldsFilled && form.action !== 'edit'}
                            type="text"
                            value={form.values.accountant_percentage}
                            error={form.validation.error.accountant_percentage}
                            helperText={
                                form.validation.message.accountant_percentage
                            }
                            onChange={(e) => {
                                const value = e.target.value
                                setForm((prevForm: any) => ({
                                    ...prevForm,
                                    values: {
                                        ...prevForm.values,
                                        accountant_percentage: value,
                                    },
                                }))
                            }}
                        />

                        <TextField
                            fullWidth
                            // required
                            label="Сумма налога"
                            variant="standard"
                            disabled={allFieldsFilled && form.action !== 'edit'}
                            type="text"
                            value={form.values.tax}
                            error={form.validation.error.tax}
                            helperText={form.validation.message.tax}
                            onChange={(e) => {
                                const value = e.target.value
                                setForm((prevForm: any) => ({
                                    ...prevForm,
                                    values: {
                                        ...prevForm.values,
                                        tax: value,
                                    },
                                }))
                            }}
                        />

                        <div className="flex gap-[20px]">
                            <Button
                                type="submit"
                                variant="contained"
                                color={userData?.confirm ? 'success' : 'black'}
                                className={`py-[12px] px-[44px] ${
                                    userData?.confirm ? 'cursor-d' : ''
                                }`}
                                style={{
                                    borderRadius: '100px',
                                    width: '200px',
                                }}
                                onClick={() => {
                                    setForm({
                                        ...form,
                                        action:
                                            form.action === 'edit'
                                                ? 'edit'
                                                : 'add',
                                    })
                                }}
                                disabled={
                                    allFieldsFilled &&
                                    form.action !== 'edit' &&
                                    userData?.confirm === 'confirm'
                                }
                            >
                                {userData?.confirm === 'confirmed'
                                    ? 'Подтверждено'
                                    : 'Подтвердить'}
                            </Button>

                            {userData?.confirm !== 'confirmed' && (
                                <>
                                    {allFieldsFilled && (
                                        <div className="border-[1px] border-black rounded-full h-[45px] w-[45px] flex justify-center items-center cursor-pointer">
                                            <EditOutlined
                                                onClick={() => {
                                                    setForm({
                                                        ...form,
                                                        action: 'edit',
                                                    })
                                                }}
                                                className="w-[80px] h-[80px]"
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </form>

                    <div className="flex">
                        <div className="flex gap-[70px] py-[20px] px-[30px] mt-[50px] border-[1px] border-[#D4D4D4] rounded-[10px]">
                            <div>
                                <h5 className="text-[14px] border-b-[2px] border-[#282828] pb-[5px] mb-1 font-semibold">
                                    Сумма от процентов
                                </h5>
                                <span className="text-[14px]">
                                    {sumManagerPercentageSum?.toFixed(0) || 0}
                                </span>
                            </div>
                            <div>
                                <h5 className="text-[14px]  border-b-[2px] border-[#282828] pb-[5px] mb-1 font-semibold">
                                    Итог
                                </h5>
                                <span className="text-[14px]">
                                    {totalSalaryWithPercentageSum?.toFixed(0)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <Tabs>
                <div className="mt-[40px]">
                    <div className="flex gap-[20px] text-white">
                        <TabList className="flex gap-[20px]">
                            {user !== 'Администратор' && (
                                <>
                                    <Tab className="tab-1">
                                        {' '}
                                        <div className="flex flex-col gap-[10px] py-[26px] px-[30px] w-[244px] rounded-[10px] cursor-pointer">
                                            <h6 className="text-[16px]">
                                                Клиенты
                                            </h6>
                                            <span className="text-[32px]">
                                                {(
                                                    Number(
                                                        analyticsCLients?.result
                                                            ?.data
                                                            ?.client_count,
                                                    ) || 0
                                                ).toFixed(0)}
                                            </span>
                                        </div>
                                    </Tab>
                                    <Tab className="tab-2">
                                        {' '}
                                        <div className="flex flex-col gap-[10px] py-[26px] px-[30px] w-[244px] rounded-[10px] cursor-pointer">
                                            <h6 className="text-[16px]">
                                                Проценты от продаж
                                            </h6>
                                            <span className="text-[32px]">
                                                {' '}
                                                {(
                                                    Number(
                                                        analyticsManagerPrecentageSum
                                                            ?.result?.data
                                                            ?.total_percentage_sum,
                                                    ) || 0
                                                ).toFixed(0)}
                                            </span>
                                        </div>
                                    </Tab>
                                    <Tab className="tab-3">
                                        <div className="flex flex-col gap-[10px] py-[26px] px-[30px] w-[244px] rounded-[10px] cursor-pointer">
                                            <h6 className="text-[16px]">
                                                Зарплата
                                            </h6>
                                            <span className="text-[32px]">
                                                {(
                                                    Number(
                                                        analyticsManagerSalary
                                                            ?.result?.data
                                                            ?.total_salary_sum,
                                                    ) || 0
                                                ).toFixed(0)}
                                            </span>
                                        </div>
                                    </Tab>
                                </>
                            )}

                            {user === 'Администратор' && (
                                <>
                                    <Tab className="tab-1">
                                        {' '}
                                        <div className="flex flex-col gap-[10px] py-[26px] px-[30px] w-[244px] rounded-[10px] cursor-pointer">
                                            <h6 className="text-[16px]">
                                                Доход
                                            </h6>
                                            <span className="text-[32px]">
                                                {(
                                                    Number(
                                                        analyticsClientsIncome
                                                            ?.result?.data
                                                            ?.total_income,
                                                    ) || 0
                                                ).toFixed(0)}
                                            </span>
                                        </div>
                                    </Tab>
                                    <Tab className="tab-2">
                                        {' '}
                                        <div className="flex flex-col gap-[10px] py-[26px] px-[30px] w-[244px] rounded-[10px] cursor-pointer">
                                            <h6 className="text-[16px]">
                                                Расход
                                            </h6>
                                            <span className="text-[32px]">
                                                {' '}
                                                {(
                                                    Number(
                                                        analyticsClientsExpense
                                                            ?.result?.data
                                                            ?.total_expense,
                                                    ) || 0
                                                ).toFixed(0)}
                                            </span>
                                        </div>
                                    </Tab>
                                    <Tab className="tab-3">
                                        <div className="flex flex-col gap-[10px] py-[26px] px-[30px] w-[244px] rounded-[10px] cursor-pointer">
                                            <h6 className="text-[16px]">
                                                {' '}
                                                Чистая прибыль
                                            </h6>
                                            <span className="text-[32px]">
                                                {(
                                                    Number(
                                                        analyticsClientsNetprofit
                                                            ?.result?.data
                                                            ?.net_profit,
                                                    ) || 0
                                                ).toFixed(0)}
                                            </span>
                                        </div>
                                    </Tab>
                                </>
                            )}
                        </TabList>

                        <div className="flex flex-col gap-[20px]">
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={ru}
                            >
                                <DatePicker
                                    className="w-[129px]"
                                    value={filter.date.date_from}
                                    onChange={(newValue) => {
                                        setFilter({
                                            ...filter,
                                            date: {
                                                ...filter.date,
                                                date_from: newValue,
                                            },
                                        })
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            required: true,
                                        },
                                    }}
                                    views={['year', 'month']}
                                    minDate={new Date('2000-01-01')}
                                    maxDate={new Date('2050-12-31')}
                                />

                                <DatePicker
                                    className="w-[129px]"
                                    value={filter.date.date_to}
                                    onChange={(newValue) => {
                                        setFilter({
                                            ...filter,
                                            date: {
                                                ...filter.date,
                                                date_to: newValue,
                                            },
                                        })
                                    }}
                                    slotProps={{
                                        textField: {
                                            variant: 'standard',
                                            required: true,
                                        },
                                    }}
                                    views={['year', 'month']}
                                    minDate={new Date('2000-01-01')}
                                    maxDate={new Date('2050-12-31')}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>

                <div className="mt-[20px]">
                    {user !== 'Администратор' && (
                        <>
                            <TabPanel>
                                {analyticsCLients && (
                                    <Line
                                        data={{
                                            labels:
                                                analyticsCLients?.result?.data?.data?.map(
                                                    (data: any) =>
                                                        moment(
                                                            data.label,
                                                        ).format(
                                                            analyticsCLients
                                                                ?.result?.data
                                                                .month
                                                                ? 'DD/YYYY'
                                                                : 'DD/MM/YYYY',
                                                        ),
                                                ) || [],
                                            datasets: [
                                                {
                                                    label: 'Клиенты',
                                                    data:
                                                        analyticsCLients?.result?.data?.data?.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#576ED0',
                                                    tension: 0.1,
                                                },
                                            ],
                                        }}
                                        options={{
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                    },
                                                    // min: 1200,
                                                    // max: 1080000,
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel>
                                {analyticsManagerPrecentageSum && (
                                    <Line
                                        data={{
                                            labels:
                                                analyticsManagerPrecentageSum?.result?.data?.chart_data.map(
                                                    (data: any) =>
                                                        moment(
                                                            data.label,
                                                        ).format(
                                                            analyticsManagerPrecentageSum
                                                                ?.result?.data
                                                                .month
                                                                ? 'DD/YYYY'
                                                                : 'DD/MM/YYYY',
                                                        ),
                                                ) || [],
                                            datasets: [
                                                {
                                                    label: 'Проценты от продаж',
                                                    data:
                                                        analyticsManagerPrecentageSum?.result?.data?.chart_data.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#576ED0',
                                                    tension: 0.1,
                                                },
                                            ],
                                        }}
                                        options={{
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel>
                                {analyticsManagerSalary && (
                                    <Line
                                        data={{
                                            labels:
                                                analyticsManagerSalary?.result?.data?.chart_data?.map(
                                                    (data: any) =>
                                                        moment(
                                                            data.label,
                                                        ).format(
                                                            analyticsManagerSalary
                                                                ?.result?.data
                                                                .month
                                                                ? 'DD/YYYY'
                                                                : 'DD/MM/YYYY',
                                                        ),
                                                ) || [],
                                            datasets: [
                                                {
                                                    label: 'Зарплата',
                                                    data:
                                                        analyticsManagerSalary?.result?.data?.chart_data?.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#576ED0',
                                                    tension: 0.1,
                                                },
                                            ],
                                        }}
                                        options={{
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </TabPanel>
                        </>
                    )}

                    {user === 'Администратор' && (
                        <>
                            <TabPanel>
                                {analyticsClientsIncome && (
                                    <Line
                                        data={{
                                            labels:
                                                analyticsClientsIncome?.result?.data?.chart_data?.map(
                                                    (data: any) =>
                                                        moment(
                                                            data.label,
                                                        ).format(
                                                            analyticsClientsIncome
                                                                ?.result?.data
                                                                .month
                                                                ? 'DD/YYYY'
                                                                : 'DD/MM/YYYY',
                                                        ),
                                                ) || [],
                                            datasets: [
                                                {
                                                    label: 'Доход',
                                                    data:
                                                        analyticsClientsIncome?.result?.data?.chart_data?.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#576ED0',
                                                    tension: 0.1,
                                                },
                                            ],
                                        }}
                                        options={{
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel>
                                {analyticsClientsExpense && (
                                    <Line
                                        data={{
                                            labels:
                                                analyticsClientsExpense?.result?.data?.chart_data.map(
                                                    (data: any) =>
                                                        moment(
                                                            data.label,
                                                        ).format(
                                                            analyticsClientsExpense
                                                                ?.result?.data
                                                                .month
                                                                ? 'DD/YYYY'
                                                                : 'DD/MM/YYYY',
                                                        ),
                                                ) || [],
                                            datasets: [
                                                {
                                                    label: 'Расход',
                                                    data:
                                                        analyticsClientsExpense?.result?.data?.chart_data.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#576ED0',
                                                    tension: 0.1,
                                                },
                                            ],
                                        }}
                                        options={{
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </TabPanel>
                            <TabPanel>
                                {analyticsClientsNetprofit && (
                                    <Line
                                        data={{
                                            labels:
                                                analyticsClientsNetprofit?.result?.data?.chart_data?.map(
                                                    (data: any) =>
                                                        moment(
                                                            data.label,
                                                        ).format(
                                                            analyticsClientsNetprofit
                                                                ?.result?.data
                                                                .month
                                                                ? 'DD/YYYY'
                                                                : 'DD/MM/YYYY',
                                                        ),
                                                ) || [],
                                            datasets: [
                                                {
                                                    label: 'Чистая прибыль',
                                                    data:
                                                        analyticsClientsNetprofit?.result?.data?.chart_data?.map(
                                                            (data: any) =>
                                                                data.data,
                                                        ) || [],
                                                    fill: false,
                                                    borderColor: '#576ED0',
                                                    tension: 0.1,
                                                },
                                            ],
                                        }}
                                        options={{
                                            scales: {
                                                x: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                                y: {
                                                    title: {
                                                        display: true,
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </TabPanel>
                        </>
                    )}
                </div>
            </Tabs>
        </div>
    )
}

export default Analytics
