import { $axios } from "../https/axiosInstance";
import { CreateCleanSearchParams, RemoveEmptyObj } from "../helpers/helpers";
import { useAsync } from "react-async-hook";

export const ReportingService = {
  GetReportingList(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/reports/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },
  async UpdateCheckbox(report: any) {
    return await $axios.put(`/reports/${report.id}/`, RemoveEmptyObj(report));
  },

  GetPeriodsList() {
    return useAsync(async () => {
      return await $axios.get("/reports/periods/");
    }, []);
  },
};
