import React, {FormEvent, useState} from 'react';
import {Checkbox, FormControlLabel, InputAdornment, TextField} from "@mui/material";
import {useDispatch} from "react-redux";
import {login} from "../store/slices/userSlice";
import {AuthService} from "../service/AuthService";
import {jwtDecode} from "jwt-decode";
import {setCookie} from "typescript-cookie";
import {access_token_name, refresh_token_name} from "../https/axiosInstance";
import LoadingButton from '@mui/lab/LoadingButton';
import KeyIcon from '@mui/icons-material/Key';
import PersonIcon from '@mui/icons-material/Person';

const formInitialState = {
    values:{
        username: '',
        password: '',
    },
    validation:{
        message:{
            username: '',
            password: '',
        },
        error:{
            username: false,
            password: false,
        }
    },
    showPassword: false,
    requested: false,
}

const Auth = () => {
    const dispatch = useDispatch()
    const [form, setForm] = useState(formInitialState)
    const submitForm = (e: FormEvent) => {
        e.preventDefault()
        setForm({
            ...form,
            requested: true,
        })

        AuthService.GetToken(form.values).then((res)=>{
            // Get the current time in seconds
            const currentTimeInSeconds = Math.floor(Date.now() / 1000);

            const accessDecode: any = jwtDecode(res.data.access)
            const refreshDecode: any = jwtDecode(res.data.refresh)

            const accessExpirationInSeconds = accessDecode.exp;
            const refreshExpirationInSeconds = refreshDecode.exp;

            // Calculate the difference in seconds
            const accessDifferenceInSeconds = accessExpirationInSeconds - currentTimeInSeconds;
            const refreshDifferenceInSeconds = refreshExpirationInSeconds - currentTimeInSeconds;

            // Convert the difference in seconds to days
            const accessDifferenceInDays = accessDifferenceInSeconds / (60 * 60 * 24);
            const refreshDifferenceInDays = refreshDifferenceInSeconds / (60 * 60 * 24);

            setCookie(access_token_name, res.data.access, {expires: accessDifferenceInDays})
            setCookie(refresh_token_name, res.data.refresh, {expires: refreshDifferenceInDays})

            // write a user

            dispatch(login({
                authed: true,
                user: res.data.user
            }))

        }).catch((err)=>{
            if(err.response.status === 401){
                setForm({
                    ...form,
                    validation: {
                        error: {
                            username: true,
                            password: true,
                        },
                        message: {
                            username: err.response.data.detail,
                            password: err.response.data.detail,
                        }
                    },
                    requested: false,
                })
            }
        })
    }
    return (
        <div className='w-full h-full min-h-screen bg-[#979CFF] flex justify-center items-center'>
            <form onSubmit={submitForm} className='min-w-[580px] py-[90px] px-[130px] rounded-[10px] bg-[#FFF] flex flex-col items-center gap-[40px]'>
                <h1 className='text-[42px] font-[600] text-black mb-[40px]'>
                    Авторизация
                </h1>
                <div className='w-full flex flex-col items-center gap-[20px]'>
                    <TextField
                        fullWidth
                        required
                        label="Логин"
                        variant="outlined"
                        type={'text'}
                        value={form.values.username}
                        error={form.validation.error.username}
                        helperText={form.validation.message.username}
                        onChange={(e)=>setForm({...form, values:{...form.values, username: e.target.value}})}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <PersonIcon/>
                                </InputAdornment>,
                            sx: { borderRadius: '100px' }
                        }}
                    />
                    <TextField
                        fullWidth
                        required
                        label="Пароль"
                        variant="outlined"
                        type={form.showPassword ? 'text' : 'password'}
                        value={form.values.password}
                        error={form.validation.error.password}
                        helperText={form.validation.message.password}
                        onChange={(e)=>setForm({...form, values:{...form.values, password: e.target.value}})}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <KeyIcon/>
                                </InputAdornment>,
                            sx: { borderRadius: '100px' }
                        }}
                    />

                    <FormControlLabel sx={{width: '100%'}} control={<Checkbox onChange={(e)=>setForm({...form, showPassword: !form.showPassword})} />} label="Показать пароль" />
                </div>
                <LoadingButton
                    fullWidth
                    variant="contained"
                    color='black'
                    loading={form.requested}
                    type={"submit"}
                    style={{ borderRadius: '100px' }}
                >
                    Войти
                </LoadingButton>
            </form>
        </div>
    );
};

export default Auth;