import * as React from "react";
import { Outlet } from "react-router";
import Header from "./Header";
import { motion as m } from "framer-motion";

const Layout = () => {
  return (
    <m.section
      className="w-full min-h-screen text-[#282828] relative bg-white flex flex-col items-center justify-start"
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
    >
      <Header />
      <div className="flex flex-col justify-start items-start w-full min-h-screen pl-[324px] pr-[80px] pt-[80px] pb-[70px] bg-[#FFF]">
        <Outlet />
      </div>
    </m.section>
  );
};

export default Layout;
