import { $axios } from "../https/axiosInstance";
import { CreateCleanSearchParams, RemoveEmptyObj } from "../helpers/helpers";
import { useAsync } from "react-async-hook";

export const SalaryService = {
  GetSalaryList(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/salary/list/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },
  GetTotalSalary() {
    return useAsync(async () => {
      return await $axios.get("/salary/total/");
    }, []);
  },
  GetSalaryById(id: any) {
    return useAsync(async () => {
      return await $axios.get(`/salary/${id}/`);
    }, [id]);
  },
  GetSalaryByUserid(id: any) {
    return useAsync(async () => {
      return await $axios.get(`/salary/user/${id}/`);
    }, [id]);
  },
  async UpdateSalary(salary: any) {
    return await $axios.put(
      `/salary/update/${salary.id}/`,
      RemoveEmptyObj(salary)
    );
  },
  async CreateSalary(salary: any) {
    return await $axios.post("/salary/create/", RemoveEmptyObj(salary));
  },
  async DeleteSalary(salary: any) {
    return await $axios.delete(`/salary/${salary.id}/`);
  },

  GetManagerSalary(searchParams: any) {
    return useAsync(async () => {
      return await $axios.get(
        "/staff/manager-salary/" + CreateCleanSearchParams(searchParams)
      );
    }, [CreateCleanSearchParams(searchParams)]);
  },
};
